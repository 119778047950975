import {ActionType, createAction} from 'typesafe-actions';

export const welcomeSceneDidActivateScene = createAction(
    'WELCOME_SCENE_DID_ACTIVATE'
)();

export const welcomeSceneDidDeactivateScene = createAction(
    'WELCOME_SCENE_DID_DEACTIVATE'
)();

export const welcomeSceneNavigateToPinballGame = createAction(
    'WELCOME_SCENE_NAVIGATE_TO_PINBALL_GAME'
)<string>();

export type WelcomeSceneActionTypes = ActionType<typeof welcomeSceneDidActivateScene> |
    ActionType<typeof welcomeSceneDidDeactivateScene> |
    ActionType<typeof welcomeSceneNavigateToPinballGame>;
