import React from 'react';
import {css} from 'aphrodite/no-important';
import styles from './styles';
import {SaleExplained} from '../../components/SaleExplained';

export const AboutTheSale: React.FunctionComponent = () => <div className={css(styles.pageContainer)}>
    <div className={css(styles.mainContainer, styles.imagePagePart)}>
        <h2>Over de veiling</h2>

        <SaleExplained/>
    </div>
</div>;
