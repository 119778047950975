import React, {useEffect, useState} from 'react';
import {Button, ButtonGroup, Col, Row} from 'react-bootstrap';
import {css} from 'aphrodite/no-important';
import styles from './styles';
import {PinballStatus} from '../../components/PinballStatus';
import pinball from '../../models/Pinball';
import ImageGallery from 'react-image-gallery';
import {CurrencyEuro} from '../../components/CurrencyEuro';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faImages, faVideo} from '@fortawesome/free-solid-svg-icons';
import pinballGif from '../../assets/images/pinball.gif';
import {PinballDTOStateEnum} from '../../generated/swagger-api';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../reducer';
import {PinballSceneReducerState} from './reducer';
import {
    PinballSceneActionTypes,
    pinballSceneDidActivateScene,
    pinballSceneDidDeactivateScene,
    pinballSceneMountedWithPinballSlug,
    pinballSceneStopListeningForGameChanges
} from './actions';
import {Dispatch} from 'redux';
import Countdown from 'react-countdown';
import {CountdownRendererFn} from 'react-countdown/dist/Countdown';
import {BiddingForm} from './components/BiddingForm';

const renderer: CountdownRendererFn = ({hours, minutes, seconds, days}) => {
    const items: string[] = [];

    if (days !== 0) {
        items.push(days === 1 ? '1 dag' : `${days} dagen`);
    }
    if (hours !== 0) {
        items.push(hours === 1 ? '1 uur' : `${hours} uren`);
    }
    items.push(minutes === 1 ? '1 minuut' : `${minutes} minuten`);

    if (days === 0 && hours === 0) {
        items.push(`${seconds} seconden`);
    }

    return <>{items.join(', ')}</>;
};

export enum Content {
    VIDEO,
    PHOTO
}

export const PinballScene: React.FunctionComponent = () => {
    const {pinballGame, loadingState} = useSelector<RootState, PinballSceneReducerState>((state) => state.pinballScene);
    const dispatch = useDispatch<Dispatch<PinballSceneActionTypes>>();
    const [currentContent, setCurrentContent] = useState<Content>(Content.PHOTO);

    const params = useParams<{ id: string }>();
    useEffect(() => {
        dispatch(pinballSceneMountedWithPinballSlug(params.id!));

        return function cleanup() {
            dispatch(pinballSceneStopListeningForGameChanges());
        };
    }, [params.id]);
    useEffect(() => {
        dispatch(pinballSceneDidActivateScene());

        return function cleanup() {
            dispatch(pinballSceneDidDeactivateScene());
        };
    }, []);

    return <React.Fragment>
        {loadingState === 'INITIAL' && <div className={css(styles.pageContainer)}>
            <Row>
                <Col xs={12} md={8}>
                    <div className={css(styles.mainContainer, styles.imagePagePart)}>
                        <h2>Bezig met zoeken...</h2>
                    </div>
                </Col>
                <Col xs={12} md={4}>
                    <img src={pinballGif} className={'img-fluid text-end'} alt={'Helaas niet gevonden...'}/>
                </Col>
            </Row>
        </div>}
        {loadingState === 'NOT_FOUND' && <div className={css(styles.pageContainer)}>
            <Row>
                <Col xs={12} md={8}>
                    <div className={css(styles.mainContainer, styles.imagePagePart)}>
                        <h2>We kunnen de flipperkast niet vinden :(</h2>

                        <p>We zijn even aan het spelen geweest om je kast te vinden, maar helaas hebben we het hem niet
                            kunnen vinden. We vermoeden dat hij wel ergens verstopt staat, dus klik boven aan op
                            'Overzicht'
                            om naar alle kasten te gaan die in de verkoop zijn.</p>

                        <p>&nbsp;</p>

                        <ButtonGroup>
                            <Button variant={'outline-primary'}>Ga naar de veilingkasten</Button>
                            <Button variant={'outline-primary'}>Ga naar jouw biedingen</Button>
                        </ButtonGroup>
                    </div>
                </Col>
                <Col xs={12} md={4}>
                    <img src={pinballGif} className={'img-fluid text-end'} alt={'Helaas niet gevonden...'}/>
                </Col>
            </Row>
        </div>}

        {loadingState === 'FOUND' && <div className={css(styles.pageContainer)}>
            <Row>
                <Col xs={12} md={8}>
                    <div className={css(styles.mainContainer)}>
                        <div className={css(styles.titlePagePart)}>
                            <h3>{pinballGame?.name}</h3>

                            <div>
                                <PinballStatus pinballGame={pinballGame!} smallFontSize={false}/>&nbsp;&nbsp;

                                <span className={css(styles.titleDescription)}>
                                    <strong>Begin prijs:</strong> <CurrencyEuro amount={pinballGame!.startingPrice}/>
                                    {pinballGame!.currentHighestBid &&
                                        <> | <strong>Huidig bod:</strong> <CurrencyEuro
                                            amount={pinballGame!.currentHighestBid}/></>}
                                </span>
                            </div>
                        </div>

                        <div className={css(styles.imagePagePart)}>
                            <div className={'d-flex btn-group mb-3'}>
                                <button
                                    className={'flex-fill btn ' +
                                        (currentContent === Content.PHOTO ? 'btn-primary' : 'btn-outline-primary')}
                                    onClick={() => setCurrentContent(Content.PHOTO)}><FontAwesomeIcon icon={faImages}/> Foto's
                                </button>
                                <button
                                    className={'flex-fill btn ' +
                                        (currentContent === Content.VIDEO ? 'btn-primary' : 'btn-outline-primary')}
                                    onClick={() => setCurrentContent(Content.VIDEO)}><FontAwesomeIcon icon={faVideo}/> Video
                                </button>
                            </div>

                            {currentContent === Content.VIDEO && <div className="ratio ratio-16x9">
                                <iframe className="embed-responsive-item" title={`Spel video ${pinballGame?.name}`}
                                        src={pinballGame?.videoUrl} allowFullScreen></iframe>
                            </div>}
                            {currentContent === Content.PHOTO && <ImageGallery
                                items={pinball.convertImagesForSlider(pinballGame?.urlsImages || [])}
                                infinite={true}
                                lazyLoad={true}
                                autoPlay={true}
                                showThumbnails={true}
                                slideDuration={1000}
                                slideInterval={5000}
                                thumbnailPosition={'right'}
                            />}
                        </div>

                        <div className={css(styles.descriptionPagePart)}>
                            <h5>Informatie over bieden</h5>
                            {pinballGame?.state === 'bidding-started' && <p>
                                Bieden is nog mogelijk voor: <Countdown date={pinballGame.biddingFinishedAt} renderer={renderer}/>
                            </p>}
                            {pinballGame?.state === 'bidding-not-started' && <p>
                                Bieding mogelijk over: <Countdown date={pinballGame.biddingStartingAt} renderer={renderer}/>
                            </p>}

                            {(pinballGame!.description && pinballGame!.description.length > 0) && <><h5>NB</h5>
                                <p className={css(styles.descriptionText)}>
                                    {pinball.convertDescription('decode', pinballGame!.description)}
                                </p></>}
                        </div>
                    </div>
                </Col>
                <Col xs={12} md={4}>
                    <div className={css(styles.mainContainer, styles.sidebarPadding)}>
                        <div className={css(styles.sidebarHeader)}>
                            <h6 className={css(styles.sidebarContentText)}>
                                Biedingen
                            </h6>
                        </div>

                        <div className={css(styles.sidebarContent)}>
                            <table className={'table bg-transparent table-borderless table-xs'}>
                                <tbody>
                                    {pinballGame!.bids.map((pinballGameBid) => {
                                        return <tr key={pinballGameBid.id}>
                                            <td>{pinballGameBid.bidderName}</td>
                                            <td className={'text-end'}><strong><CurrencyEuro
                                                amount={pinballGameBid.amount}/></strong></td>
                                        </tr>;
                                    })}
                                    {!pinballGame?.bids.length && <>
                                        <tr>
                                            <td colSpan={3}>
                                                Geen biedingen geplaatst
                                            </td>
                                        </tr>
                                    </>}
                                </tbody>
                            </table>

                            <hr/>

                            {pinballGame?.state === PinballDTOStateEnum.BiddingStarted && <BiddingForm pinballGame={pinballGame}/>}
                            {pinballGame?.state === PinballDTOStateEnum.BiddingNotStarted &&
                                <p><i>Bieden is mogelijk zodra de veiling open is</i></p>}
                            {pinballGame?.state === PinballDTOStateEnum.BiddingFinished &&
                                <p><i>Bieden is niet meer mogelijk, veiling is gesloten</i></p>}
                        </div>
                    </div>
                    <div className={css(styles.mainContainer, styles.sidebarPadding)}>
                        <div className={css(styles.sidebarHeader)}>
                            <h6 className={css(styles.sidebarContentText)}>Kenmerken</h6>
                        </div>
                        <div className={css(styles.sidebarContent)}>
                            <table className={'table bg-transparent table-borderless table-sm'}>
                                <tbody>
                                    {pinballGame?.attributes.map(({key, value}) => <tr key={`${key}-${value}`}>
                                        <th>{key}</th>
                                        <td>:</td>
                                        <td>{value}</td>
                                    </tr>)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>}
    </React.Fragment>;
};
