import {Configuration, PinballApi, PinballBidApi, UserApi} from '../../generated/swagger-api';
import {from, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {
    swaggerApiServicePinballControllerListFailedReceivingDataWithUnauthorizedError,
    swaggerApiServicePinballControllerListFailedReceivingDataWithUnexpectedError,
    swaggerApiServicePinballControllerListReceivedData
} from './actions/pinballControllerList';
import {
    swaggerApiServicePinballControllerSingleFailedReceivingDataWithUnauthorizedError,
    swaggerApiServicePinballControllerSingleFailedReceivingDataWithUnexpectedError,
    swaggerApiServicePinballControllerSingleReceivedData
} from './actions/pinballControllerSingle';
import {
    swaggerApiServicePinballControllerPlacingBidFailedReceivingDataWithUnauthorizedError,
    swaggerApiServicePinballControllerPlacingBidFailedReceivingDataWithUnexpectedError,
    swaggerApiServicePinballControllerSavedPinballBid
} from './actions/pinballControllerPlaceBid';
import {AxiosError} from 'axios';
import {
    swaggerApiServiceUserControllerLoadingProfileDataFailedWithUnauthorizedException,
    swaggerApiServiceUserControllerLoadingProfileDataFailedWithUnexpectedException,
    swaggerApiServiceUserControllerProfileData
} from './actions/userControllerProfile';
import {
    swaggerApiServicePinballBidControllerReceivedMyBidsData,
    swaggerApiServicePinballBidControllerReceivingBidsDataFailedWithUnauthorizedError,
    swaggerApiServicePinballBidControllerReceivingBidsDataFailedWithUnexpectedError
} from './actions/pinballBidControllerMyBids';
import {
    swaggerApiServicePinballBidControllerReceivedStatsData,
    swaggerApiServicePinballBidControllerReceivingStatsDataFailedWithUnexpectedError
} from './actions/pinballStatistics';

export class SwaggerApiService {

    loadGamesList(accessToken: string) {
        const promise = (new PinballApi(this.buildConfiguration(accessToken)))
            .pinballControllerList();

        return from(promise)
            .pipe(
                map((response) => swaggerApiServicePinballControllerListReceivedData(response.data)),
                catchError((error: AxiosError) => {
                    if (!error.response) {
                        return of(swaggerApiServicePinballControllerListFailedReceivingDataWithUnexpectedError());
                    }

                    switch (error.response.status) {
                        case 403:
                            return of(swaggerApiServicePinballControllerListFailedReceivingDataWithUnauthorizedError());
                        case 500:
                        default:
                            return of(swaggerApiServicePinballControllerListFailedReceivingDataWithUnexpectedError());
                    }
                })
            );
    }

    loadSingleGame(accessToken: string, slug: string) {
        const promise = (new PinballApi(this.buildConfiguration(accessToken)))
            .pinballControllerSingle(slug);

        return from(promise)
            .pipe(
                map((response) => swaggerApiServicePinballControllerSingleReceivedData(response.data)),
                catchError((error: AxiosError) => {
                    if (!error.response) {
                        return of(swaggerApiServicePinballControllerListFailedReceivingDataWithUnexpectedError());
                    }

                    switch (error.response.status) {
                        case 403:
                            return of(swaggerApiServicePinballControllerSingleFailedReceivingDataWithUnauthorizedError());
                        case 500:
                        default:
                            return of(swaggerApiServicePinballControllerSingleFailedReceivingDataWithUnexpectedError());
                    }
                })
            );
    }

    saveBidOnGame(accessToken: string, pinballId: string, amount: number) {
        const promise = (new PinballApi(this.buildConfiguration(accessToken)))
            .pinballControllerPlaceBid({
                pinballId,
                amount
            });

        return from(promise)
            .pipe(
                map(() => swaggerApiServicePinballControllerSavedPinballBid()),
                catchError((error: AxiosError) => {
                    if (!error.response) {
                        return of(swaggerApiServicePinballControllerPlacingBidFailedReceivingDataWithUnexpectedError());
                    }

                    switch (error.response.status) {
                        case 403:
                            return of(swaggerApiServicePinballControllerPlacingBidFailedReceivingDataWithUnauthorizedError());
                        case 500:
                        default:
                            return of(swaggerApiServicePinballControllerPlacingBidFailedReceivingDataWithUnexpectedError());
                    }
                })
            );
    }

    loadUserProfile(accessToken: string) {
        const promise = (new UserApi(this.buildConfiguration(accessToken)))
            .userControllerProfile();

        return from(promise)
            .pipe(
                map((response) => swaggerApiServiceUserControllerProfileData(response.data)),
                catchError((error: AxiosError) => {
                    if (!error.response) {
                        return of(swaggerApiServiceUserControllerLoadingProfileDataFailedWithUnexpectedException());
                    }

                    switch (error.response.status) {
                        case 403:
                            return of(swaggerApiServiceUserControllerLoadingProfileDataFailedWithUnauthorizedException());
                        case 500:
                        default:
                            return of(swaggerApiServiceUserControllerLoadingProfileDataFailedWithUnexpectedException());
                    }
                })
            );
    }

    userBids(accessToken: string) {
        const promise = (new PinballBidApi(this.buildConfiguration(accessToken)))
            .pinballBidControllerMyBids();

        return from(promise)
            .pipe(
                map((response) => swaggerApiServicePinballBidControllerReceivedMyBidsData(response.data)),
                catchError((error: AxiosError) => {
                    if (!error.response) {
                        return of(swaggerApiServicePinballBidControllerReceivingBidsDataFailedWithUnexpectedError());
                    }

                    switch (error.response.status) {
                        case 403:
                            return of(swaggerApiServicePinballBidControllerReceivingBidsDataFailedWithUnauthorizedError());
                        case 500:
                        default:
                            return of(swaggerApiServicePinballBidControllerReceivingBidsDataFailedWithUnexpectedError());
                    }
                })
            );
    }

    loadStatistics(accessToken: string) {
        const promise = (new PinballApi(this.buildConfiguration(accessToken)))
            .pinballControllerBiddingStats();

        return from(promise)
            .pipe(
                map((response) => swaggerApiServicePinballBidControllerReceivedStatsData(response.data)),
                catchError(() => of(swaggerApiServicePinballBidControllerReceivingStatsDataFailedWithUnexpectedError()))
            );
    }

    private buildConfiguration(accessToken: string): Configuration {
        return new Configuration({
            accessToken,
            basePath: `${process.env.REACT_APP_CONFIG_API_BASE_URL}/api`
        });
    }
}
