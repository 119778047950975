import {FC} from 'react';

export const SaleExplained: FC = () => {

    return <>
        <h5>Belangrijke informatie over de veiling</h5>

        <ul>
            <li>Alleen NFV-leden mogen bieden</li>
            <li>Per kast geldt een minimumprijs. Die minimumprijs is gebaseerd op ervaring, fingerspitzen, Yin en Yang. Een uitgangspunt was
                dat we geen prijzen willen die handel in de hand werken. Aan de andere kant houden we ook rekening houden met het feit dat
                aan zo goed als alle kasten nog het nodige werk is.
            </li>
            <li>De kasten zijn getest en werken, maar verkeren over het algemeen niet in goede staat. Dit geldt zowel optisch als
                mechanisch/elektronisch . Er zullen zich naar alle waarschijnlijkheid storingen en defecten voordoen als de kast eenmaal
                thuis staat. Het zijn kasten "met werk".
            </li>
            <li>Biedingen zijn bindend: Elk online bod dat je plaatst is definitief en bindend. Annuleren is niet mogelijk. Er is geen
                bedenktijd of herroepingsrecht.
            </li>
        </ul>

        <p><strong>Daarom voordat je een bod uitbrengt:</strong></p>

        <ul>
            <li>Bestudeer de foto's en filmpjes goed!</li>
            <li>Kom naar één van de kijkdagen! Inschrijven wanneer je komt kijken is verplicht. Kom je alleen voor de
                veilingkasten? Stuur dan een e-mail naar bvis@nfvpinball.nl. De kijkdagen zijn op:
                <ol>
                    <li><a href={'https://www.nfvpinball.nl/activiteiten/82/clubhuisdag-15-maart-2025?act=hYlBbfjq82JVIo-cKOu61Xw=='}
                           target={'_blank'}>Clubhuisdag op 15 maart (14-16 uur)</a></li>
                    <li><a href={'https://www.nfvpinball.nl/activiteiten/82/funhouse-friday-28-3-2025?act=hMz5EezUvxmP8yd3s2hsxZw==&cat=8'}
                           target={'_blank'}>Funhouse Friday op 28 maart (19-21 uur)</a></li>
                    <li><a href={'https://www.nfvpinball.nl/activiteiten/82/klusdag-5-april-2025?act=hun1lzTT/xfVeQjCzP/BISg==&cat=5'}
                           target={'_blank'}>Klusdag op 5 april (14-16 uur)</a></li>
                </ol>
            </li>
        </ul>

        <h5>Geen garantie</h5>
        <p>De flipperkasten worden verkocht zoals ze zijn, zonder garantie van welke aard dan ook. Je koopt de flipperkast in de staat
            waarin die zich bevindt op het moment van de verkoop, met alle zichtbare en onzichtbare gebreken. De NFV neemt geen
            verantwoordelijkheid voor eventuele defecten of problemen die na de verkoop aan het licht komen. Als koper aanvaardt je het
            risico van eventuele gebreken of problemen die op dat moment niet bekend waren of niet zichtbaar waren. Je kunt dus geen
            aanspraak maken op garantie of compensatie voor eventuele gebreken na de verkoop.</p>

        <h5>Het bieden</h5>
        <p>De veiling start met de eerste kast op zaterdag 15 maart om 12.00 uur. Elke 10 minuten wordt een volgende kast aangeboden. De
            veiling van de eerste kast sluit op zondag 13 april om 12.00 uur. Als er in de laatste twee minuten van de veiling een bod
            wordt gedaan, wordt de veiling automatisch telkens met twee minuten verlengd. Dus als er in de twee laatste minuten een bod
            wordt gedaan, wordt de veiling weer met twee minuten verlengd. Dit totdat er in die twee minuten geen nieuw bod is gedaan. Zo
            geven we alle bieders een eerlijke kans en voorkomen we dat de snelste laatste bieder meer kans maakt op het hoogste bod.</p>

        <p>Bieden geschiedt uitsluitend via de veilingwebsite. Je voor en achternaam zullen op de veilingwebsite bij het bod geplaatst
            worden, je gaat hiermee akkoord als je gebruik maakt van de veilingwebsite. Ter info: deze website is alleen zichtbaar voor
            ingelogde NFV-leden.</p>

        <h5>De afloop van de veiling</h5>
        <p>De hoogste bieder krijgt een factuur toegestuurd met het te betalen bedrag. Het factuurbedrag moet binnen vijf dagen ontvangen
            zijn op de rekening van de NFV.</p>

        <p>De hoogste bieder kan, na betaling, de kast ophalen op zaterdag 19 april (tijd nader te bepalen). NB zaterdag 19 april is alleen
            een afhaaldag: het clubhuis is dan niet open voor bezoek of om kasten te spelen. Mocht ophalen op 19 april voor de hoogste
            bieder niet mogelijk zijn, dan kan in overleg met Arie Pijl een afspraak worden gemaakt voor een andere dag.</p>

        <h5>Kan mijn bieding geannuleerd worden?</h5>
        <p>Het annuleren van een bod is helaas niet mogelijk. Daarom hebben we bewust twee stappen ingebouwd om per ongeluk bieden te
            voorkomen:</p>
        <ol>
            <li>Bied nu</li>
            <li>Bevestig dit bod</li>
        </ol>
        <p>Tot en met stap 2 heb je de mogelijkheid om de bieding te annuleren. Na het definitief bevestigen van je bod bij stap 2, is het
            niet meer mogelijk om het bod te annuleren. Let dus goed op bij het bevestigen van het bod, omdat je na deze stap verplicht bent
            om je aan het bod te houden.</p>

        <h5>De veilingsite</h5>
        <p>De veilingsite is met zorg gebouwd en vervolgens getest, maar veilen is niet ons vak. Het kan dus zijn dat er onvolkomenheden
            zijn. In dergelijke gevallen kan de NFV besluiten om de veiling opnieuw te starten. Bieders op een kast waarvan de veiling
            opnieuw wordt gestart, worden hiervan op de hoogte gesteld.</p>
    </>;
};
