import {createAction} from 'typesafe-actions';
import {PinballDTO} from '../../../generated/swagger-api';

export const swaggerApiServicePinballControllerListReceivedData = createAction(
    'SWAGGER_API_SERVICE_PINBALL_CONTROLLER_LIST_RECEIVED_DATA'
)<PinballDTO[]>();

export const swaggerApiServicePinballControllerListFailedReceivingDataWithUnauthorizedError = createAction(
    'SWAGGER_API_SERVICE_PINBALL_CONTROLLER_LIST_FAILED_RECEIVING_DATA_WITH_UNAUTHORIZED_ERROR'
)();

export const swaggerApiServicePinballControllerListFailedReceivingDataWithUnexpectedError = createAction(
    'SWAGGER_API_SERVICE_PINBALL_CONTROLLER_LIST_FAILED_RECEIVING_DATA_WITH_UNEXPECTED_ERROR'
)();
