import React, {useEffect} from 'react';
import {css} from 'aphrodite/no-important';
import styles from './styles';
import pinballFlipper from '../../assets/images/pinball-flipper.png';
import {useDispatch} from 'react-redux';
import {Dispatch} from 'redux';
import {NewSessionSceneActionTypes, newSessionSceneStartNewSessionWithId} from './actions';
import {useParams} from 'react-router';

export const NewSessionScene: React.FunctionComponent = () => {
    const dispatch = useDispatch<Dispatch<NewSessionSceneActionTypes>>();
    const {id} = useParams<{ id: string }>();

    useEffect(() => {
        dispatch(newSessionSceneStartNewSessionWithId(id!));
    }, [id]);

    return <>
        <div className={css(styles.pageHeader)} style={{backgroundImage: `url('${pinballFlipper}')`}}>
            <p>Bezig met inloggen...</p>
            <h2>Welkom (terug)!</h2>
        </div>

        <div className={css(styles.pageContent)}>
            <p>We hebben je inloggegevens goed ontvangen en zijn je op dit moment aan het inloggen, nog even geduld...</p>
        </div>
    </>;
};
