import {ActionType, createAction} from 'typesafe-actions';
import {UserDTO} from '../../generated/swagger-api';

export const authenticationModuleUserAuthenticated = createAction(
    'AUTHENTICATION_MODULE_USER_AUTHENTICATED'
)<string>();

export const authenticationModuleStartUserLogout = createAction(
    'AUTHENTICATION_MODULE_START_USER_LOGOUT'
)();

export const authenticationModuleFinishedUserLogout = createAction(
    'AUTHENTICATION_MODULE_FINISHED_USER_LOGOUT'
)();

export const authenticationModuleLoadedUserProfileData = createAction(
    'APPLICATION_MODULE_LOADED_USER_PROFILE_DATA'
)<UserDTO>();

export const authenticationModuleLoadingUserProfileFailed = createAction(
    'APPLICATION_MODULE_LOADING_USER_PROFILE_FAILED'
)();

export type AuthenticationModuleActionsType = ActionType<typeof authenticationModuleUserAuthenticated> |
    ActionType<typeof authenticationModuleStartUserLogout> |
    ActionType<typeof authenticationModuleLoadedUserProfileData> |
    ActionType<typeof authenticationModuleLoadingUserProfileFailed> |
    ActionType<typeof authenticationModuleFinishedUserLogout>;
