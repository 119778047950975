import {combineEpics, Epic} from 'redux-observable';
import {filter, ignoreElements, map, mergeMap, tap} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {welcomeSceneDidActivateScene, welcomeSceneNavigateToPinballGame} from '../../scenes/Welcome/actions';
import {applicationModuleUpdateCurrentScene} from '../../modules/application/actions';
import {Scenes} from '../../scenes';
import {swaggerApiService} from '../../services';
import {DependencyEpic} from '../../store';
import {router} from '../../router';

const changeSceneApplicationModuleOnWelcomeSceneDidActivate: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(welcomeSceneDidActivateScene)),
        map(() => applicationModuleUpdateCurrentScene(Scenes.WELCOME))
    );

const loadPinballsOnWelcomeSceneDidActive: DependencyEpic = (action$, state$) => action$
    .pipe(
        filter(isActionOf(welcomeSceneDidActivateScene)),
        mergeMap(() => swaggerApiService.loadGamesList(state$.value.authenticationModule.sessionId!))
    );

const pushRouteOnNavigateToPinballGameRequested: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(welcomeSceneNavigateToPinballGame)),
        map(({payload: slug}) => `/flipperkast/${slug}`),
        tap((route) => router.navigate(route)),
        ignoreElements()
    );

export const welcomeSceneEpics: Epic = combineEpics(
    changeSceneApplicationModuleOnWelcomeSceneDidActivate,
    pushRouteOnNavigateToPinballGameRequested,
    loadPinballsOnWelcomeSceneDidActive
);
