import {authenticationModuleReducer, AuthenticationModuleReducerState} from './modules/authentication/reducer';
import {combineReducers} from 'redux';
import {applicationModuleReducer, ApplicationModuleReducerState} from './modules/application/reducer';
import {pinballModuleReducer, PinballModuleReducerState} from './modules/pinball/reducer';
import {pinballSceneReducer, PinballSceneReducerState} from './scenes/Pinball/reducer';
import {myBidsSceneReducer, MyBidsSceneReducerState} from './scenes/MyBids/reducer';
import {tourSceneReducer, TourSceneReducerState} from './scenes/Tour/reducer';
import {adminSceneReducer, AdminSceneReducer} from './scenes/Admin/reducer';

export interface RootState {
    adminScene: AdminSceneReducer;
    applicationModule: ApplicationModuleReducerState;
    authenticationModule: AuthenticationModuleReducerState;
    myBids: MyBidsSceneReducerState;
    pinballModule: PinballModuleReducerState;
    pinballScene: PinballSceneReducerState;
    tourScene: TourSceneReducerState;
}

export const rootReducer = combineReducers({
    adminScene: adminSceneReducer,
    applicationModule: applicationModuleReducer,
    authenticationModule: authenticationModuleReducer,
    myBids: myBidsSceneReducer,
    pinballModule: pinballModuleReducer,
    pinballScene: pinballSceneReducer,
    tourScene: tourSceneReducer
});
