import {combineEpics, Epic} from 'redux-observable';
import {filter, ignoreElements, map, mergeMap, tap} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {
    pinballSceneAddBidToGame,
    pinballSceneAddedBidToGame,
    pinballSceneClearCurrentBidValue,
    pinballSceneDidActivateScene,
    pinballSceneFailedToAddBidToGame,
    pinballSceneLoadPinballGameDetails,
    pinballSceneMountedWithPinballSlug,
    pinballSceneReceivedPinballGame
} from '../../scenes/Pinball/actions';
import {applicationModuleUpdateCurrentScene} from '../../modules/application/actions';
import {Scenes} from '../../scenes';
import {swaggerApiService} from '../../services';
import {DependencyEpic} from '../../store';
import {swaggerApiServicePinballControllerSingleReceivedData} from '../../services/SwaggerApiService/actions/pinballControllerSingle';
import {pinballModuleReceivedPinballGameUpdate} from '../../modules/pinball/actions';
import {
    swaggerApiServicePinballControllerPlacingBidFailedReceivingDataWithUnauthorizedError,
    swaggerApiServicePinballControllerPlacingBidFailedReceivingDataWithUnexpectedError,
    swaggerApiServicePinballControllerSavedPinballBid
} from '../../services/SwaggerApiService/actions/pinballControllerPlaceBid';
import {toast} from 'react-toastify';

const changeSceneApplicationModuleOnPinballSceneDidActivate: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(pinballSceneDidActivateScene)),
        map(() => applicationModuleUpdateCurrentScene(Scenes.PINBALL))
    );

const loadSinglePinballDataOnPinballSceneDidActive: DependencyEpic = (action$, state$) => action$
    .pipe(
        filter(isActionOf(pinballSceneMountedWithPinballSlug)),
        map(({payload: slug}) => pinballSceneLoadPinballGameDetails(slug))
    );

const callBackendApiOnLoadPinballData: DependencyEpic = (action$, state$) => action$
    .pipe(
        filter(isActionOf(pinballSceneLoadPinballGameDetails)),
        mergeMap(({payload: slug}) => swaggerApiService.loadSingleGame(state$.value.authenticationModule.sessionId!, slug))
    );

const storePinballSnapshotOnReceivedSinglePinballDate: DependencyEpic = (action$) => action$
    .pipe(
        filter(isActionOf(swaggerApiServicePinballControllerSingleReceivedData)),
        map(({payload: game}) => pinballSceneReceivedPinballGame({loadingState: 'FOUND', pinballGame: game}))
    );

const placeBidUsingSwaggerApiServiceOnAddBidToGame: DependencyEpic = (action$, state$) => action$
    .pipe(
        filter(isActionOf(pinballSceneAddBidToGame)),
        mergeMap(({payload: {pinballId, value}}) => swaggerApiService.saveBidOnGame(
            state$.value.authenticationModule.sessionId!,
            pinballId,
            value
        ))
    );

const updatePinballGameOnPinballModuleReceivedUpdatePinball: DependencyEpic = (action$, state$) => action$
    .pipe(
        filter(isActionOf(pinballModuleReceivedPinballGameUpdate)),
        filter(({payload: pinball}) =>
            !!state$.value.pinballScene.pinballGame && state$.value.pinballScene.pinballGame.id === pinball.id),
        map(({payload: {slug}}) => pinballSceneLoadPinballGameDetails(slug))
    );

const addedBidToGameOnSwaggerApiSavedPinballBid: DependencyEpic = (action$) => action$
    .pipe(
        filter(isActionOf(swaggerApiServicePinballControllerSavedPinballBid)),
        map(() => pinballSceneAddedBidToGame())
    );

const failedToAddBidToGameOnSwaggerApiErrorResponses: DependencyEpic = (action$) => action$
    .pipe(
        filter(isActionOf([
            swaggerApiServicePinballControllerPlacingBidFailedReceivingDataWithUnauthorizedError,
            swaggerApiServicePinballControllerPlacingBidFailedReceivingDataWithUnexpectedError
        ])),
        map(() => pinballSceneFailedToAddBidToGame())
    );

const clearCurrentBidValuesOnAddedBidToGame: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(pinballSceneAddedBidToGame)),
        map(() => pinballSceneClearCurrentBidValue())
    );

const showToastMessageOnAddedBidToGame: DependencyEpic = (action$, state$) => action$
    .pipe(
        filter(isActionOf(pinballSceneAddedBidToGame)),
        tap(() => toast(`Je bod is geplaatst op ${state$.value.pinballScene.pinballGame!.name}`, {type: 'success'})),
        ignoreElements()
    );

const showToastMessageOnFailedToAddBidToGame: DependencyEpic = (action$, state$) => action$
    .pipe(
        filter(isActionOf(pinballSceneFailedToAddBidToGame)),
        tap(() => toast(`Helaas, je bod kon niet geplaats worden op ${state$.value.pinballScene.pinballGame!.name}`, {type: 'error'})),
        ignoreElements()
    );

export const pinballSceneEpic = combineEpics(
    changeSceneApplicationModuleOnPinballSceneDidActivate,
    loadSinglePinballDataOnPinballSceneDidActive,
    callBackendApiOnLoadPinballData,
    storePinballSnapshotOnReceivedSinglePinballDate,
    placeBidUsingSwaggerApiServiceOnAddBidToGame,
    updatePinballGameOnPinballModuleReceivedUpdatePinball,
    addedBidToGameOnSwaggerApiSavedPinballBid,
    failedToAddBidToGameOnSwaggerApiErrorResponses,
    clearCurrentBidValuesOnAddedBidToGame,
    showToastMessageOnAddedBidToGame,
    showToastMessageOnFailedToAddBidToGame
);
