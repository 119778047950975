import {ActionType, createAction} from 'typesafe-actions';
import {PinballDTO} from '../../generated/swagger-api';
import {PinballWebsiteUpdateDTO} from '../../models/PinballWebsiteUpdateDTO';

export const pinballModuleUpdatePinballGames = createAction(
    'PINBALL_MODULE_UPDATE_PINBALL_GAMES'
)<PinballDTO[]>();

export const pinballModuleReceivedPinballGameUpdate = createAction(
    'PINBALL_MODULE_RECEIVED_PINBALL_GAME_UPDATE'
)<PinballWebsiteUpdateDTO>();

export type PinballModuleActionTypes = ActionType<typeof pinballModuleUpdatePinballGames> |
    ActionType<typeof pinballModuleReceivedPinballGameUpdate>;
