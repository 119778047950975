import React, {FC, FormEvent, useRef} from 'react';
import pinball from '../../../../models/Pinball';
import {Button, ButtonGroup, FormControl, InputGroup} from 'react-bootstrap';
import {css} from 'aphrodite/no-important';
import styles from '../../styles';
import {
    PinballSceneActionTypes,
    pinballSceneAddBidToGame,
    pinballSceneUpdateManualBidValue,
    pinballSceneValidateInputValue
} from '../../actions';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faGavel} from '@fortawesome/free-solid-svg-icons';
import debounce from 'lodash.debounce';
import {PinballDTO} from '../../../../generated/swagger-api';
import {PinballSceneReducerState} from '../../reducer';
import {RootState} from '../../../../reducer';
import {useDispatch, useSelector} from 'react-redux';
import {Dispatch} from 'redux';
import {Props} from './Props';

export const BiddingForm: FC<Props> = ({pinballGame}) => {
    const {validatedManualBidValue, manualBidValue} = useSelector<RootState, PinballSceneReducerState>((state) => state.pinballScene);
    const dispatch = useDispatch<Dispatch<PinballSceneActionTypes>>();
    const onValidateInputValue = () => dispatch(pinballSceneValidateInputValue());

    const delayedValidationTrigger = useRef(debounce(() => onValidateInputValue(), 400, {
        leading: false,
        trailing: true
    })).current;

    const addAmountToGame = (value: number) =>
        addBidToGame(pinballGame.id, pinballGame.name, (pinballGame.currentHighestBid ?? pinballGame.startingPrice) + value);

    const handleFormSubmit = (e: FormEvent<HTMLFormElement>, pinballSubmit: PinballDTO) => {
        e.preventDefault();

        if (!validatedManualBidValue) {
            delayedValidationTrigger();
            return;
        }

        if (isNaN(Number(manualBidValue))) {
            delayedValidationTrigger();
            return;
        }

        addBidToGame(pinballSubmit.id, pinballSubmit.name, Number(manualBidValue));
    };

    const addBidToGame = (pinballId: string, pinballName: string, value: number) => {
        const formatted = new Intl.NumberFormat('nl', {
            style: 'currency',
            currency: 'EUR'
        }).format(value);

        if (window.confirm(`Weet je zeker dat je bod wilt plaatsen op ${pinballName} van ${formatted}?`)) {
            dispatch(pinballSceneAddBidToGame({pinballId, value}));
        }
    };

    return <form
        onSubmit={(e) => handleFormSubmit(e, pinballGame)}
    >
        <h5>Bieden</h5>

        <p>Minimum volgend bod: € {pinball.nextBidValue(pinballGame)}</p>

        <InputGroup hasValidation>
            <FormControl
                className={validatedManualBidValue === false ? css(styles.shakeInput) : undefined}
                type={'number'}
                onChange={(e) => {
                    delayedValidationTrigger();
                    dispatch(pinballSceneUpdateManualBidValue(e.target.value));
                }}
                onBlur={() => onValidateInputValue()}
                value={manualBidValue}
                isInvalid={validatedManualBidValue === false ? true : undefined}
                isValid={validatedManualBidValue ? true : undefined}
            />
            <Button variant="primary" type={'submit'}>
                <FontAwesomeIcon icon={faGavel}/> Bieden
            </Button>
            <FormControl.Feedback type="invalid">Je moet een geldig bedrag invullen</FormControl.Feedback>
        </InputGroup>

        <div className={css(styles.upBidText)}>Huidige bod verhogen met:</div>
        <div className={css(styles.buttonGroupBidUp)}>
            <ButtonGroup size={'sm'}>
                {pinballGame.bidValues.map((value) =>
                    <Button key={`bid-value-${value}`} onClick={() => addAmountToGame(value)}
                            variant={'outline-info'}>€ {value}</Button>)}
            </ButtonGroup>
        </div>
    </form>;
};
