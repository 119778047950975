import React, {PropsWithChildren, useContext} from 'react';
import {Col, Container, Nav, Navbar, NavDropdown, Row} from 'react-bootstrap';
import {AuthenticationContext} from '../../../contexts/authentication';
import {Scenes} from '../../../scenes';
import {css} from 'aphrodite/no-important';
import styles from './styles';
import pinballFlipper from '../../../assets/images/pinball-flipper.png';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../reducer';
import {ApplicationModuleReducerState} from '../../../modules/application/reducer';
import {ADMIN, MY_BIDS, WELCOME} from '../../../routes';
import {NavLink} from 'react-router-dom';
import {Dispatch} from 'redux';
import {AuthenticationModuleActionsType, authenticationModuleStartUserLogout} from '../../../modules/authentication/actions';

export const MainLayout: React.FunctionComponent<PropsWithChildren> = ({children}) => {
    const authenticationContext = useContext(AuthenticationContext);
    const {currentScene} = useSelector<RootState, ApplicationModuleReducerState>((state) => state.applicationModule);
    const dispatch = useDispatch<Dispatch<AuthenticationModuleActionsType>>();

    const renderUserFullName = (): string => {
        if (!authenticationContext.userProfile) {
            return '';
        }

        return `${authenticationContext.userProfile.firstName} ${authenticationContext.userProfile.lastName}`;
    };

    const handleLogoutClick = () => {
        if (window.confirm('Weet je zeker dat je wilt uitloggen?')) {
            dispatch(authenticationModuleStartUserLogout());
        }
    };

    const year = (new Date()).getFullYear();

    return <div
        style={{backgroundImage: `url("${pinballFlipper}")`}}
        className={css(styles.pageContainer)}
    >
        <Navbar bg={'dark'} variant={'dark'} expand={'lg'} className={'justify-content-between'}>
            <Container>
                <NavLink className={'navbar-brand'} to={WELCOME}>NFV Kasten Veiling</NavLink>
                <Navbar.Toggle aria-controls={'main-navigation'}/>
                <Navbar.Collapse id={'main-navigation'} className={'justify-content-between'}>
                    <Nav className="mr-auto">
                        <NavLink
                            to={WELCOME}
                            className={currentScene === Scenes.WELCOME ? 'nav-link active' : 'nav-link'}
                        >
                            Overzicht
                        </NavLink>
                        <NavLink
                            to={MY_BIDS}
                            className={currentScene === Scenes.MY_BIDS ? 'nav-link active' : 'nav-link'}
                        >
                            Mijn biedingen
                        </NavLink>
                        {authenticationContext.userProfile?.isAdmin && <NavLink
                            to={ADMIN}
                            className={currentScene === Scenes.ADMIN ? 'nav-link active' : 'nav-link'}
                        >
                            Beheer
                        </NavLink>}
                    </Nav>

                    {authenticationContext.authenticated && <>
                        <Nav>
                            <NavDropdown id={''} title={`Welkom terug, ${renderUserFullName()}!`}>
                                <NavDropdown.Item onClick={handleLogoutClick}>Uitloggen</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </>}
                </Navbar.Collapse>
            </Container>
        </Navbar>
        <Container fluid={'lg'}>
            {children}

            <hr/>

            <footer className={css(styles.footerContainer)}>
                <Row>
                    <Col xs={12} md={6}>
                        &copy; {year} Nederlandse Flipper Vereniging
                    </Col>
                    <Col xs={12} md={6}>
                        <div className={'text-end'}>
                            <NavLink to={'/over-de-veiling'}>Over de NFV kasten veiling</NavLink>
                            &nbsp;&bull;&nbsp;
                            <NavLink to={'/hoe-werkt-de-kasten-veiling'}>Hoe werkt de kasten veiling?</NavLink>
                        </div>
                    </Col>
                </Row>
            </footer>
        </Container>
    </div>;
};
