import {Reducer} from 'redux';
import {MyBidsSceneActionTypes, myBidsSceneDidDeactivateScene, myBidsSceneStoreUserBidsData} from './actions';
import {UserBidDTO} from '../../generated/swagger-api';
import {getType} from 'typesafe-actions';

export interface MyBidsSceneReducerState {
    bids: UserBidDTO[] | null;
}

const initialState: MyBidsSceneReducerState = {
    bids: null
};

export const myBidsSceneReducer: Reducer<MyBidsSceneReducerState, MyBidsSceneActionTypes> =
    (state: MyBidsSceneReducerState = initialState, action: MyBidsSceneActionTypes): MyBidsSceneReducerState => {
        switch (action.type) {
            case getType(myBidsSceneStoreUserBidsData):
                return {
                    ...state,
                    bids: action.payload
                };
            case getType(myBidsSceneDidDeactivateScene):
                return {
                    ...state,
                    bids: null
                };
            default:
                return {
                    ...state
                };
        }
    };
