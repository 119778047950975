import {BadgeProps} from 'react-bootstrap';
import {ReactImageGalleryItem} from 'react-image-gallery';
import {PinballDTO, PinballDTOStateEnum} from '../generated/swagger-api';

class Pinball {

    statusBadge(pinballGame: PinballDTO): {
        badgeType: BadgeProps['bg'];
        text: string
    } {
        if (pinballGame.state === PinballDTOStateEnum.BiddingStarted) {
            return {
                badgeType: 'success',
                text: 'Veiling is open'
            };
        } else if (pinballGame.state === PinballDTOStateEnum.BiddingFinished) {
            return {
                badgeType: 'danger',
                text: 'Veiling afgelopen'
            };
        }

        return {
            badgeType: 'info',
            text: 'Veiling nog niet open'
        };
    }

    convertDescription(action: 'encode' | 'decode', description: string): string {
        return description.replace(new RegExp('\\|n', 'g'), '\n');
    }

    convertImagesForSlider(images: string[]): ReactImageGalleryItem[] {
        return images.map<ReactImageGalleryItem>((url: string) => ({
            original: `${process.env.REACT_APP_CONFIG_API_BASE_URL}${url}/original`,
            thumbnail: `${process.env.REACT_APP_CONFIG_API_BASE_URL}${url}/compressed`
        }));
    }

    nextBidValue(pinballDTO: PinballDTO): number {
        return (pinballDTO.currentHighestBid ? pinballDTO.currentHighestBid : pinballDTO.startingPrice) + 10;
    }
}

const pinball = new Pinball();

export default pinball;
