import {ActionType, createAction} from 'typesafe-actions';
import {PINBALL_LOADING_STATE} from './reducer';
import {PinballDTO} from '../../generated/swagger-api';

export const pinballSceneDidActivateScene = createAction(
    'PINBALL_SCENE_DID_ACTIVATE'
)();

export const pinballSceneDidDeactivateScene = createAction(
    'PINBALL_SCENE_DID_DEACTIVATE'
)();

export const pinballSceneMountedWithPinballSlug = createAction(
    'PINBALL_SCENE_MOUNTED_WITH_PINBALL_SLUG'
)<string>();

export const pinballSceneLoadPinballGameDetails = createAction(
    'PINBALL_SCENE_LOAD_PINBALL_GAME_DETAILS'
)<string>();

export const pinballSceneStartListeningForGameChanges = createAction(
    'PINBALL_SCENE_START_LISTENING_FOR_GAME_CHANGES'
)<string>();

export const pinballSceneStopListeningForGameChanges = createAction(
    'PINBALL_SCENE_STOP_LISTENING_FOR_GAME_CHANGES'
)();

export const pinballSceneReceivedPinballGame = createAction(
    'PINBALL_SCENE_RECEIVED_PINBALL_GAME'
)<{ loadingState: PINBALL_LOADING_STATE, pinballGame: PinballDTO | null }>();

export const pinballSceneUpdatePinballGameData = createAction(
    'PINBALL_SCENE_UPDATE_PINBALL_GAME_DATA'
)<PinballDTO>();

export const pinballSceneAddBidToGame = createAction(
    'PINBALL_SCENE_ADD_BIT_TO_GAME'
)<{ pinballId: string, value: number }>();

export const pinballSceneUpdateManualBidValue = createAction(
    'PINBALL_SCENE_UPDATE_MANUAL_BID_VALUE'
)<string>();

export const pinballSceneValidateInputValue = createAction(
    'PINBALL_SCENE_VALIDATE_INPUT_VALUE'
)();

export const pinballSceneAddedBidToGame = createAction(
    'PINBALL_SCENE_ADDED_BIT_TO_GAME'
)();

export const pinballSceneFailedToAddBidToGame = createAction(
    'PINBALL_SCENE_FAILED_ADD_BID_TO_GAME'
)();

export const pinballSceneClearCurrentBidValue = createAction(
    'PINBALL_SCENE_CLEAR_CURRENT_BID_VALUE'
)();

export type PinballSceneActionTypes = ActionType<typeof pinballSceneDidActivateScene> |
    ActionType<typeof pinballSceneDidDeactivateScene> |
    ActionType<typeof pinballSceneMountedWithPinballSlug> |
    ActionType<typeof pinballSceneStartListeningForGameChanges> |
    ActionType<typeof pinballSceneStopListeningForGameChanges> |
    ActionType<typeof pinballSceneReceivedPinballGame> |
    ActionType<typeof pinballSceneUpdatePinballGameData> |
    ActionType<typeof pinballSceneAddBidToGame> |
    ActionType<typeof pinballSceneValidateInputValue> |
    ActionType<typeof pinballSceneUpdateManualBidValue> |
    ActionType<typeof pinballSceneClearCurrentBidValue>;
