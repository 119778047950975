import {Reducer} from 'redux';
import {TourSceneActionTypes, tourSceneDidDeactivateScene, tourSceneSetStep} from './actions';
import {getType} from 'typesafe-actions';

export enum TourSceneStep {
    WELCOME = 'welcome',
    BIDDING = 'bidding',
    HOW_TO = 'how-to'
}

export interface TourSceneReducerState {
    currentStep: TourSceneStep;
}

const initialState: TourSceneReducerState = {
    currentStep: TourSceneStep.WELCOME
};

export const tourSceneReducer: Reducer<TourSceneReducerState, TourSceneActionTypes> =
    (state: TourSceneReducerState = initialState, action: TourSceneActionTypes) => {
        switch (action.type) {
            case getType(tourSceneDidDeactivateScene):
                return {
                    currentStep: TourSceneStep.WELCOME
                };
            case getType(tourSceneSetStep):
                return {
                    ...state,
                    currentStep: action.payload
                };
            default:
                return {
                    ...state
                };
        }
    };
