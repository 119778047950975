import React from 'react';
import {css} from 'aphrodite/no-important';
import styles from '../AboutTheSale/styles';
import {HowSaleWorks} from '../../components/HowToSale';

export const HowToSale: React.FunctionComponent = () => <div className={css(styles.pageContainer)}>
    <div className={css(styles.mainContainer, styles.imagePagePart)}>
        <h2>Hoe werkt de kastenveiling?</h2>

        <HowSaleWorks/>
    </div>
</div>;
