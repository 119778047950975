import {combineEpics, Epic} from 'redux-observable';
import {DependencyEpic} from '../../store';
import {delay, filter, ignoreElements, map, tap} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {
    applicationModuleApplicationBootCompleted,
    applicationModuleApplicationColdBoot,
    applicationModuleBootWithSessionUrl
} from '../../modules/application/actions';
import {TOUR, WELCOME} from '../../routes';
import {socketService} from '../../services';
import {router} from '../../router';

const bootApplicationWithSessionUrlOnColdBootStartedApplication: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(applicationModuleApplicationColdBoot)),
        filter(() => router.state.location.pathname.indexOf('/newsession') > -1),
        map(() => router.state.location.pathname.replace('/newsession/', '')),
        map((sessionId) => applicationModuleBootWithSessionUrl(sessionId))
    );

const loadSessionFromStorageOnColdBootStartedApplication: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(applicationModuleApplicationColdBoot)),
        filter(() => router.state.location.pathname.indexOf('/newsession') === -1),
        tap(() => {
            return window.location.href = process.env.REACT_APP_CONFIG_SSO_REDIRECT_URL as string;
        }),
        ignoreElements()
    );

const navigateToWelcomeSceneOnApplicationBootCompleted: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(applicationModuleApplicationBootCompleted)),
        delay(500),
        map(() => {
            let route = TOUR;

            if (localStorage.getItem('tour-completed-2025')) {
                const lastRoute = localStorage.getItem('last-route');
                if (lastRoute) {
                    route = lastRoute;
                } else {
                    route = WELCOME;
                }
            }

            return route;
        }),
        tap((route) => router.navigate(route)),
        ignoreElements()
    );

const connectToWebsocketOnApplicationBootCompleted: DependencyEpic = (action$, state$) => action$
    .pipe(
        filter(isActionOf(applicationModuleApplicationBootCompleted)),
        tap(() => socketService.init(state$.value.authenticationModule.sessionId!)),
        ignoreElements()
    );

export const applicationEpics: Epic = combineEpics(
    bootApplicationWithSessionUrlOnColdBootStartedApplication,
    navigateToWelcomeSceneOnApplicationBootCompleted,
    loadSessionFromStorageOnColdBootStartedApplication,
    connectToWebsocketOnApplicationBootCompleted
);
