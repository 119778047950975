import {BiddingStatsDTO} from '../../generated/swagger-api';
import {Reducer} from 'redux';
import {AdminSceneActionTypes, adminSceneUpdateStatistics} from './actions';
import {getType} from 'typesafe-actions';

export interface AdminSceneReducer {
    statistics: BiddingStatsDTO[];
}

const initialState: AdminSceneReducer = {
    statistics: []
};

export const adminSceneReducer: Reducer<AdminSceneReducer, AdminSceneActionTypes> = (state = initialState, action) => {
    switch (action.type) {
        case getType(adminSceneUpdateStatistics):
            return {
                ...state,
                statistics: action.payload
            };
        default:
            return {
                ...state
            };
    }
};
