import {ActionType, createAction} from 'typesafe-actions';
import {TourSceneStep} from './reducer';

export const tourSceneDidActivateScene = createAction(
    'TOUR_SCENE_DID_ACTIVATE'
)();

export const tourSceneDidDeactivateScene = createAction(
    'TOUR_SCENE_DID_DEACTIVATE'
)();

export const tourSceneGoToNextStep = createAction(
    'TOUR_SCENE_GO_TO_NEXT_STEP'
)();

export const tourSceneSetStep = createAction(
    'TOUR_SCENE_SET_STEP'
)<TourSceneStep>();

export const tourSceneFinishTour = createAction(
    'TOUR_SCENE_FINISH_TOUR'
)();

export type TourSceneActionTypes = ActionType<typeof tourSceneDidActivateScene> |
    ActionType<typeof tourSceneDidDeactivateScene> |
    ActionType<typeof tourSceneGoToNextStep> |
    ActionType<typeof tourSceneSetStep> |
    ActionType<typeof tourSceneFinishTour>;
