import {FC} from 'react';

export const HowSaleWorks: FC = () => {

    return <>
        <p>Op deze pagina geven een korte tekstuele uitleg geven over hoe de veilingwebsite werkt, deze website bestaat uit een aantal
            onderdelen: Het kastenoverzicht, de kastdetailpagina en jouw biedingenoverzicht.</p>

        <h5>Kastenoverzicht</h5>
        <p>Wanneer je deze rondleiding hebt afgerond kom je automatisch in het kastenoverzicht terecht. Hierin staan alle kasten die in de
            veiling worden aangeboden met enkele foto's. Je kan het huidige hoogste bod zien, of de minimale prijs van de kast wanneer nog
            geen biedingen zijn geplaatst. Ook kan je met de knop 'Bekijk deze kast' de details bekijken en eventueel een bod plaatsen.</p>

        <h5>Kastdetailpagina</h5>
        <p>Op deze pagina vind je alle informatie over de veiling kast, zoals de laatste 10 biedingen, wat extra informatie zoals merk en
            type (EM/SS). Ook is er op deze pagina een fotoweergave ingebouwd waarin je alle foto's van de kast rustig kunt bekijken. Dit
            kan zowel in volledig scherm als de normale weergave, ook zit links onder een pauze knop. Rechts onder de lijst met biedingen
            kan je een bod uitbrengen, dit kan met het invoerveld waar je handmatig je bod in kan vullen of met een van de knoppen die onder
            het invoerveld staan.</p>

        <h5>Mijn biedingen</h5>
        <p>Op deze pagina kan je al je biedingen inzien die je gedaan hebt, wanneer het bedrag groen gekleurd is heb jij nog steeds het
            hoogste bod staan, wanneer het bedrag rood is betekent het dat je overboden bent. Met de meest rechter knop kan je ook
            gemakkelijk terug gaan naar de flipperkastdetailpagina in kwestie.</p>
    </>;
};
