import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../reducer';
import {Card, Col, Row} from 'react-bootstrap';
import {PinballStatus} from '../../components/PinballStatus';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {css} from 'aphrodite/no-important';
import styles from './styles';
import {CurrencyEuro} from '../../components/CurrencyEuro';
import {NavLink} from 'react-router-dom';
import {PinballModuleReducerState} from '../../modules/pinball/reducer';
import {Dispatch} from 'redux';
import {WelcomeSceneActionTypes, welcomeSceneDidActivateScene} from './actions';

export const WelcomeScene: React.FunctionComponent = () => {
    const {games} = useSelector<RootState, PinballModuleReducerState>((state) => state.pinballModule);
    const dispatch = useDispatch<Dispatch<WelcomeSceneActionTypes>>();

    useEffect(() => {
        dispatch(welcomeSceneDidActivateScene());

        return function cleanup() {
            dispatch(welcomeSceneDidActivateScene());
        };
    }, []);

    return <Row className={css(styles.gamesContainer)}>
        <Col xs={12}>
            <Row>
                {games.map((game) => <Col xs={12} md={3} key={game.id}>
                    <Card>
                        {game.featuredImageUrl && <div className={css(styles.imageContainer) + ' d-none d-md-block'}>
                            <div
                                style={{backgroundImage: `url(${process.env.REACT_APP_CONFIG_API_BASE_URL}${game.featuredImageUrl})`}}
                                className={css(styles.image)}
                            />
                        </div>}
                        <Card.Body>
                            <Card.Title className={'d-flex justify-content-between'}>
                                <div>{game.name}</div>
                                <div>
                                    <PinballStatus pinballGame={game} smallFontSize={true}/>
                                </div>
                            </Card.Title>
                            <Card.Text>
                                {!game.currentHighestBid && <>Bieden vanaf:&nbsp;
                                    <CurrencyEuro amount={game.startingPrice}/></>}
                                {game.currentHighestBid && <strong>Huidig bod:&nbsp;
                                    <CurrencyEuro amount={game.currentHighestBid}/>
                                </strong>}
                            </Card.Text>
                            <div className="d-grid gap-2">
                                <NavLink to={`/flipperkast/${game.slug}`} className={'btn btn-outline-info'}>
                                    Bekijk deze kast <FontAwesomeIcon icon={faChevronRight}/>
                                </NavLink>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>)}
            </Row>
        </Col>
    </Row>;
};
