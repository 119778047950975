import {Reducer} from 'redux';
import {PinballModuleActionTypes, pinballModuleUpdatePinballGames} from './actions';
import {getType} from 'typesafe-actions';
import {PinballDTO} from '../../generated/swagger-api';

export interface PinballModuleReducerState {
    games: PinballDTO[];
}

const initialState: PinballModuleReducerState = {
    games: []
};

export const pinballModuleReducer: Reducer<PinballModuleReducerState, PinballModuleActionTypes> =
    (state: PinballModuleReducerState = initialState, action: PinballModuleActionTypes): PinballModuleReducerState => {
        switch (action.type) {
            case getType(pinballModuleUpdatePinballGames):
                return {
                    ...state,
                    games: action.payload
                };
            default:
                return {
                    ...state
                };
        }
    };
