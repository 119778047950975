import React, {useContext} from 'react';
import {AuthenticationContext} from '../../contexts/authentication';
import {Props} from './Props';
import {Navigate} from 'react-router';

export const PrivateRoute: React.FunctionComponent<Props> = ({component: RenderComponent}) => {
    const authentication = useContext(AuthenticationContext);

    return authentication.authenticated ? <RenderComponent/> : <></>;
};
