import {createAction} from 'typesafe-actions';
import {BiddingStatsDTO, UserBidDTO} from '../../../generated/swagger-api';

export const swaggerApiServicePinballBidControllerReceivedStatsData = createAction(
    'SWAGGER_API_SERVICE_PINBALL_BID_CONTROLLER_RECEIVED_STATS_DATA'
)<BiddingStatsDTO[]>();

export const swaggerApiServicePinballBidControllerReceivingStatsDataFailedWithUnexpectedError = createAction(
    'SWAGGER_API_SERVICE_PINBALL_BID_CONTROLLER_RECEIVING_STATS_DATA_FAILED_WITH_UNEXPECTED_ERROR'
)();
