import {combineEpics, Epic} from 'redux-observable';
import {applicationEpics} from './epics/application';
import {authenticationModuleEpics} from './epics/authenticationModule';
import {pinballModuleEpics} from './epics/pinballModule';
import {pinballSceneEpic} from './epics/pinballScene';
import {welcomeSceneEpics} from './epics/welcomeScene';
import {myBidsSceneEpic} from './epics/myBidsSceneEpics';
import {tourSceneEpics} from './epics/tourSceneEpics';
import {adminSceneEpics} from './epics/adminScene';

export const rootEpic: Epic = combineEpics(
    applicationEpics,
    authenticationModuleEpics,
    myBidsSceneEpic,
    pinballModuleEpics,
    pinballSceneEpic,
    tourSceneEpics,
    welcomeSceneEpics,
    adminSceneEpics
);
