import {combineEpics, Epic} from 'redux-observable';
import {delay, filter, ignoreElements, map, mergeMap, tap} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {applicationModuleApplicationBootCompleted, applicationModuleBootWithSessionUrl} from '../../modules/application/actions';
import {
    authenticationModuleLoadedUserProfileData,
    authenticationModuleLoadingUserProfileFailed,
    authenticationModuleStartUserLogout,
    authenticationModuleUserAuthenticated
} from '../../modules/authentication/actions';
import {
    swaggerApiServicePinballControllerListFailedReceivingDataWithUnauthorizedError
} from '../../services/SwaggerApiService/actions/pinballControllerList';
import {
    swaggerApiServicePinballControllerSingleFailedReceivingDataWithUnauthorizedError
} from '../../services/SwaggerApiService/actions/pinballControllerSingle';
import {
    swaggerApiServicePinballControllerPlacingBidFailedReceivingDataWithUnauthorizedError
} from '../../services/SwaggerApiService/actions/pinballControllerPlaceBid';
import {socketService, swaggerApiService} from '../../services';
import {toast} from 'react-toastify';
import {
    swaggerApiServiceUserControllerLoadingProfileDataFailedWithUnauthorizedException,
    swaggerApiServiceUserControllerLoadingProfileDataFailedWithUnexpectedException,
    swaggerApiServiceUserControllerProfileData
} from '../../services/SwaggerApiService/actions/userControllerProfile';
import {ERROR_SCENE} from '../../routes';
import {
    swaggerApiServicePinballBidControllerReceivingBidsDataFailedWithUnauthorizedError
} from '../../services/SwaggerApiService/actions/pinballBidControllerMyBids';
import {router} from '../../router';

const redirectUserToAuth = (): string => window.location.href = process.env.REACT_APP_CONFIG_SSO_REDIRECT_URL!;

const storeSessionInformationOnApplicationModuleBootWithSessionUrl: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(applicationModuleBootWithSessionUrl)),
        map(({payload: sessionId}) => authenticationModuleUserAuthenticated(sessionId))
    );

const loadUserProfileDataOnUserAuthenticated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(authenticationModuleUserAuthenticated)),
        mergeMap(({payload: sessionId}) => swaggerApiService.loadUserProfile(sessionId))
    );

const loadedUserProfileDataOnSwaggerApiUserControllerProfileData: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(swaggerApiServiceUserControllerProfileData)),
        map(({payload: userProfile}) => authenticationModuleLoadedUserProfileData(userProfile))
    );

const failedToLoadUserProfileOnSwaggerApiControllerLoadingProfileDataFailed: Epic = (action$) => action$
    .pipe(
        filter(isActionOf([
            swaggerApiServiceUserControllerLoadingProfileDataFailedWithUnauthorizedException,
            swaggerApiServiceUserControllerLoadingProfileDataFailedWithUnexpectedException
        ])),
        map(() => authenticationModuleLoadingUserProfileFailed())
    );

const pushErrorRouteOnLoadingUserProfileFailed: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(authenticationModuleLoadingUserProfileFailed)),
        tap(() => router.navigate(ERROR_SCENE)),
        ignoreElements()
    );

const redirectToLoginOnLoadingUserProfileFailed: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(authenticationModuleLoadingUserProfileFailed)),
        delay(5000),
        tap(redirectUserToAuth),
        ignoreElements()
    );

const redirectUserOnUnauthorizedRequest: Epic = (action$) => action$
    .pipe(
        filter(isActionOf([
            swaggerApiServicePinballBidControllerReceivingBidsDataFailedWithUnauthorizedError,
            swaggerApiServicePinballControllerListFailedReceivingDataWithUnauthorizedError,
            swaggerApiServicePinballControllerSingleFailedReceivingDataWithUnauthorizedError,
            swaggerApiServicePinballControllerPlacingBidFailedReceivingDataWithUnauthorizedError
        ])),
        tap(redirectUserToAuth),
        ignoreElements()
    );

const messageUserOnWebsocketAuthError: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(applicationModuleApplicationBootCompleted)),
        mergeMap(() => socketService.onAuthError()
            .pipe(
                tap(() => toast('Automatische updates uitgeschakeld', {type: 'warning'})),
                ignoreElements()
            ))
    );

const redirectToLogoffPageOnUserRequestedSignOut: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(authenticationModuleStartUserLogout)),
        tap(() => {
            return window.location.href = process.env.REACT_APP_CONFIG_SSO_SIGN_OUT_URL as string;
        }),
        ignoreElements()
    );

const applicationBootFirebaseOnLoadedUserProfileData: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(authenticationModuleLoadedUserProfileData)),
        map(() => applicationModuleApplicationBootCompleted())
    );

export const authenticationModuleEpics: Epic = combineEpics(
    storeSessionInformationOnApplicationModuleBootWithSessionUrl,
    redirectUserOnUnauthorizedRequest,
    messageUserOnWebsocketAuthError,
    loadUserProfileDataOnUserAuthenticated,
    loadedUserProfileDataOnSwaggerApiUserControllerProfileData,
    failedToLoadUserProfileOnSwaggerApiControllerLoadingProfileDataFailed,
    pushErrorRouteOnLoadingUserProfileFailed,
    redirectToLoginOnLoadingUserProfileFailed,
    redirectToLogoffPageOnUserRequestedSignOut,
    applicationBootFirebaseOnLoadedUserProfileData
);
