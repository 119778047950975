import {createAction} from 'typesafe-actions';
import {UserBidDTO} from '../../../generated/swagger-api';

export const swaggerApiServicePinballBidControllerReceivedMyBidsData = createAction(
    'SWAGGER_API_SERVICE_PINBALL_BID_CONTROLLER_RECEIVED_MY_BIDS_DATA'
)<UserBidDTO[]>();

export const swaggerApiServicePinballBidControllerReceivingBidsDataFailedWithUnauthorizedError = createAction(
    'SWAGGER_API_SERVICE_PINBALL_BID_CONTROLLER_RECEIVING_BIDS_DATA_FAILED_WITH_UNAUTHORIZED_ERROR'
)();

export const swaggerApiServicePinballBidControllerReceivingBidsDataFailedWithUnexpectedError = createAction(
    'SWAGGER_API_SERVICE_PINBALL_BID_CONTROLLER_RECEIVING_BIDS_DATA_FAILED_WITH_UNEXPECTED_ERROR'
)();
