/* tslint:disable */
/* eslint-disable */
/**
 * NFV Kasten veiling
 * Swagger API voor NFV kasten veiling
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface BiddingStatsDTO
 */
export interface BiddingStatsDTO {
    /**
     * 
     * @type {string}
     * @memberof BiddingStatsDTO
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof BiddingStatsDTO
     */
    'startingPrice': number;
    /**
     * 
     * @type {number}
     * @memberof BiddingStatsDTO
     */
    'highestBid'?: number;
    /**
     * 
     * @type {number}
     * @memberof BiddingStatsDTO
     */
    'bidTurnover'?: number;
}
/**
 * 
 * @export
 * @interface InternalServerErrorDTO
 */
export interface InternalServerErrorDTO {
    /**
     * 
     * @type {string}
     * @memberof InternalServerErrorDTO
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface NotFoundDTO
 */
export interface NotFoundDTO {
    /**
     * 
     * @type {string}
     * @memberof NotFoundDTO
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface PinballAttributeDTO
 */
export interface PinballAttributeDTO {
    /**
     * 
     * @type {string}
     * @memberof PinballAttributeDTO
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof PinballAttributeDTO
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface PinballBidDTO
 */
export interface PinballBidDTO {
    /**
     * 
     * @type {string}
     * @memberof PinballBidDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PinballBidDTO
     */
    'bidderName': string;
    /**
     * 
     * @type {number}
     * @memberof PinballBidDTO
     */
    'amount': number;
}
/**
 * 
 * @export
 * @interface PinballDTO
 */
export interface PinballDTO {
    /**
     * 
     * @type {string}
     * @memberof PinballDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PinballDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PinballDTO
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof PinballDTO
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof PinballDTO
     */
    'videoUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof PinballDTO
     */
    'startingPrice': number;
    /**
     * 
     * @type {number}
     * @memberof PinballDTO
     */
    'currentHighestBid': number | null;
    /**
     * 
     * @type {string}
     * @memberof PinballDTO
     */
    'state': PinballDTOStateEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof PinballDTO
     */
    'bidValues': Array<number>;
    /**
     * 
     * @type {Array<PinballAttributeDTO>}
     * @memberof PinballDTO
     */
    'attributes': Array<PinballAttributeDTO>;
    /**
     * 
     * @type {string}
     * @memberof PinballDTO
     */
    'featuredImageUrl': string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PinballDTO
     */
    'urlsImages': Array<string>;
    /**
     * 
     * @type {Array<PinballBidDTO>}
     * @memberof PinballDTO
     */
    'bids': Array<PinballBidDTO>;
    /**
     * 
     * @type {string}
     * @memberof PinballDTO
     */
    'biddingStartingAt': string;
    /**
     * 
     * @type {string}
     * @memberof PinballDTO
     */
    'biddingFinishedAt': string;
}

export const PinballDTOStateEnum = {
    BiddingNotStarted: 'bidding-not-started',
    BiddingStarted: 'bidding-started',
    BiddingFinished: 'bidding-finished'
} as const;

export type PinballDTOStateEnum = typeof PinballDTOStateEnum[keyof typeof PinballDTOStateEnum];

/**
 * 
 * @export
 * @interface PinballPlaceBidDTO
 */
export interface PinballPlaceBidDTO {
    /**
     * 
     * @type {string}
     * @memberof PinballPlaceBidDTO
     */
    'pinballId': string;
    /**
     * 
     * @type {number}
     * @memberof PinballPlaceBidDTO
     */
    'amount': number;
}
/**
 * 
 * @export
 * @interface UnauthorizedDTO
 */
export interface UnauthorizedDTO {
    /**
     * 
     * @type {string}
     * @memberof UnauthorizedDTO
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface UserBidDTO
 */
export interface UserBidDTO {
    /**
     * 
     * @type {string}
     * @memberof UserBidDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserBidDTO
     */
    'pinballId': string;
    /**
     * 
     * @type {string}
     * @memberof UserBidDTO
     */
    'pinballSlug': string;
    /**
     * 
     * @type {string}
     * @memberof UserBidDTO
     */
    'pinballName': string;
    /**
     * 
     * @type {number}
     * @memberof UserBidDTO
     */
    'amount': number;
    /**
     * 
     * @type {boolean}
     * @memberof UserBidDTO
     */
    'highestBid': boolean;
}
/**
 * 
 * @export
 * @interface UserDTO
 */
export interface UserDTO {
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'lastName': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserDTO
     */
    'isAdmin': boolean;
}

/**
 * PinballApi - axios parameter creator
 * @export
 */
export const PinballApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinballControllerBiddingStats: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pinball/stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinballControllerList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pinball`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PinballPlaceBidDTO} pinballPlaceBidDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinballControllerPlaceBid: async (pinballPlaceBidDTO: PinballPlaceBidDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pinballPlaceBidDTO' is not null or undefined
            assertParamExists('pinballControllerPlaceBid', 'pinballPlaceBidDTO', pinballPlaceBidDTO)
            const localVarPath = `/pinball/place-bid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pinballPlaceBidDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinballControllerSingle: async (slug: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('pinballControllerSingle', 'slug', slug)
            const localVarPath = `/pinball/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PinballApi - functional programming interface
 * @export
 */
export const PinballApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PinballApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pinballControllerBiddingStats(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BiddingStatsDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pinballControllerBiddingStats(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PinballApi.pinballControllerBiddingStats']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pinballControllerList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PinballDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pinballControllerList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PinballApi.pinballControllerList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PinballPlaceBidDTO} pinballPlaceBidDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pinballControllerPlaceBid(pinballPlaceBidDTO: PinballPlaceBidDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pinballControllerPlaceBid(pinballPlaceBidDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PinballApi.pinballControllerPlaceBid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pinballControllerSingle(slug: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PinballDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pinballControllerSingle(slug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PinballApi.pinballControllerSingle']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PinballApi - factory interface
 * @export
 */
export const PinballApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PinballApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinballControllerBiddingStats(options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingStatsDTO>> {
            return localVarFp.pinballControllerBiddingStats(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinballControllerList(options?: RawAxiosRequestConfig): AxiosPromise<Array<PinballDTO>> {
            return localVarFp.pinballControllerList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PinballPlaceBidDTO} pinballPlaceBidDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinballControllerPlaceBid(pinballPlaceBidDTO: PinballPlaceBidDTO, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.pinballControllerPlaceBid(pinballPlaceBidDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinballControllerSingle(slug: string, options?: RawAxiosRequestConfig): AxiosPromise<PinballDTO> {
            return localVarFp.pinballControllerSingle(slug, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PinballApi - object-oriented interface
 * @export
 * @class PinballApi
 * @extends {BaseAPI}
 */
export class PinballApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PinballApi
     */
    public pinballControllerBiddingStats(options?: RawAxiosRequestConfig) {
        return PinballApiFp(this.configuration).pinballControllerBiddingStats(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PinballApi
     */
    public pinballControllerList(options?: RawAxiosRequestConfig) {
        return PinballApiFp(this.configuration).pinballControllerList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PinballPlaceBidDTO} pinballPlaceBidDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PinballApi
     */
    public pinballControllerPlaceBid(pinballPlaceBidDTO: PinballPlaceBidDTO, options?: RawAxiosRequestConfig) {
        return PinballApiFp(this.configuration).pinballControllerPlaceBid(pinballPlaceBidDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PinballApi
     */
    public pinballControllerSingle(slug: string, options?: RawAxiosRequestConfig) {
        return PinballApiFp(this.configuration).pinballControllerSingle(slug, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PinballBidApi - axios parameter creator
 * @export
 */
export const PinballBidApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinballBidControllerMyBids: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pinball-bid/my-bids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PinballBidApi - functional programming interface
 * @export
 */
export const PinballBidApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PinballBidApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pinballBidControllerMyBids(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserBidDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pinballBidControllerMyBids(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PinballBidApi.pinballBidControllerMyBids']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PinballBidApi - factory interface
 * @export
 */
export const PinballBidApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PinballBidApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinballBidControllerMyBids(options?: RawAxiosRequestConfig): AxiosPromise<Array<UserBidDTO>> {
            return localVarFp.pinballBidControllerMyBids(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PinballBidApi - object-oriented interface
 * @export
 * @class PinballBidApi
 * @extends {BaseAPI}
 */
export class PinballBidApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PinballBidApi
     */
    public pinballBidControllerMyBids(options?: RawAxiosRequestConfig) {
        return PinballBidApiFp(this.configuration).pinballBidControllerMyBids(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PinballImageApi - axios parameter creator
 * @export
 */
export const PinballImageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} pinball 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinballImageControllerImage: async (pinball: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pinball' is not null or undefined
            assertParamExists('pinballImageControllerImage', 'pinball', pinball)
            const localVarPath = `/pinball-image/{pinball}/original`
                .replace(`{${"pinball"}}`, encodeURIComponent(String(pinball)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} pinball 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinballImageControllerImageCompressed: async (pinball: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pinball' is not null or undefined
            assertParamExists('pinballImageControllerImageCompressed', 'pinball', pinball)
            const localVarPath = `/pinball-image/{pinball}/compressed`
                .replace(`{${"pinball"}}`, encodeURIComponent(String(pinball)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PinballImageApi - functional programming interface
 * @export
 */
export const PinballImageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PinballImageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} pinball 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pinballImageControllerImage(pinball: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pinballImageControllerImage(pinball, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PinballImageApi.pinballImageControllerImage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} pinball 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pinballImageControllerImageCompressed(pinball: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pinballImageControllerImageCompressed(pinball, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PinballImageApi.pinballImageControllerImageCompressed']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PinballImageApi - factory interface
 * @export
 */
export const PinballImageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PinballImageApiFp(configuration)
    return {
        /**
         * 
         * @param {string} pinball 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinballImageControllerImage(pinball: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.pinballImageControllerImage(pinball, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} pinball 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinballImageControllerImageCompressed(pinball: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.pinballImageControllerImageCompressed(pinball, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PinballImageApi - object-oriented interface
 * @export
 * @class PinballImageApi
 * @extends {BaseAPI}
 */
export class PinballImageApi extends BaseAPI {
    /**
     * 
     * @param {string} pinball 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PinballImageApi
     */
    public pinballImageControllerImage(pinball: string, options?: RawAxiosRequestConfig) {
        return PinballImageApiFp(this.configuration).pinballImageControllerImage(pinball, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} pinball 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PinballImageApi
     */
    public pinballImageControllerImageCompressed(pinball: string, options?: RawAxiosRequestConfig) {
        return PinballImageApiFp(this.configuration).pinballImageControllerImageCompressed(pinball, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerProfile: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerProfile(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerProfile(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userControllerProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerProfile(options?: RawAxiosRequestConfig): AxiosPromise<UserDTO> {
            return localVarFp.userControllerProfile(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerProfile(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerProfile(options).then((request) => request(this.axios, this.basePath));
    }
}



