import {combineEpics, Epic} from 'redux-observable';
import {filter, ignoreElements, map, mergeMap, tap} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {
    myBidsSceneDidActivateScene,
    myBidsSceneLoadCurrentUserBids,
    myBidsSceneStoreUserBidsData,
    myBidsSceneVisitPinballScene
} from '../../scenes/MyBids/actions';
import {applicationModuleUpdateCurrentScene} from '../../modules/application/actions';
import {Scenes} from '../../scenes';
import {swaggerApiServicePinballBidControllerReceivedMyBidsData} from '../../services/SwaggerApiService/actions/pinballBidControllerMyBids';
import {DependencyEpic} from '../../store';
import {swaggerApiService} from '../../services';
import {router} from '../../router';

const updateCurrentSceneOnActiveMyBidsScene: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(myBidsSceneDidActivateScene)),
        map(() => applicationModuleUpdateCurrentScene(Scenes.MY_BIDS))
    );

const loadCurrentUserBidsOnSceneActive: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(myBidsSceneDidActivateScene)),
        map(() => myBidsSceneLoadCurrentUserBids())
    );

const loadUserBidsWithSwaggerApiOnLoadCurrentUserBids: DependencyEpic = (action$, state$) => action$
    .pipe(
        filter(isActionOf(myBidsSceneLoadCurrentUserBids)),
        mergeMap(() => swaggerApiService.userBids(state$.value.authenticationModule.sessionId!))
    );

const storeUserBidsOnSwaggerApiReceivedUserBids: Epic = action$ => action$
    .pipe(
        filter(isActionOf(swaggerApiServicePinballBidControllerReceivedMyBidsData)),
        map(({payload: userBids}) => myBidsSceneStoreUserBidsData(userBids))
    );

const pushRouteOnVisitPinballScene: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(myBidsSceneVisitPinballScene)),
        map(({payload: slug}) => `/flipperkast/${slug}`),
        tap((route) => router.navigate(route)),
        ignoreElements()
    );

export const myBidsSceneEpic: Epic = combineEpics(
    updateCurrentSceneOnActiveMyBidsScene,
    loadCurrentUserBidsOnSceneActive,
    storeUserBidsOnSwaggerApiReceivedUserBids,
    loadUserBidsWithSwaggerApiOnLoadCurrentUserBids,
    pushRouteOnVisitPinballScene
);
