import React from 'react';
import {AuthenticationContextProvider, AuthenticationContextState} from '../../contexts/authentication';
import {RouterProvider} from 'react-router-dom';
import {router} from '../../router';
import {useSelector} from 'react-redux';
import {RootState} from '../../reducer';
import {AuthenticationModuleReducerState} from '../../modules/authentication/reducer';

export const App: React.FunctionComponent = () => {
    const {authenticated, userProfile} = useSelector<RootState, AuthenticationModuleReducerState>((state) => state.authenticationModule);

    const authenticationContextProviderValue: AuthenticationContextState = {
        authenticated,
        userProfile
    };

    return <AuthenticationContextProvider value={authenticationContextProviderValue}>
        <RouterProvider router={router}/>
    </AuthenticationContextProvider>;
};
