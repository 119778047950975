import {FC, useEffect, useMemo} from 'react';
import {Dispatch} from 'redux';
import {AdminSceneActionTypes, adminSceneDidActivateScene, adminSceneDidDeactivateScene} from './actions';
import {useDispatch, useSelector} from 'react-redux';
import {css} from 'aphrodite/no-important';
import styles from './styles';
import {RootState} from '../../reducer';
import {BiddingStatsDTO} from '../../generated/swagger-api';
import {CurrencyEuro} from '../../components/CurrencyEuro';


export const Admin: FC = () => {
    const dispatch: Dispatch<AdminSceneActionTypes> = useDispatch<Dispatch<AdminSceneActionTypes>>();
    const statistics = useSelector<RootState, BiddingStatsDTO[]>((state) => state.adminScene.statistics);

    useEffect(() => {
        dispatch(adminSceneDidActivateScene());

        return () => {
            dispatch(adminSceneDidDeactivateScene());
        };
    }, []);

    const lowestBids = useMemo(() => {
        const minValue = statistics
            .filter((s) => !!s.highestBid)
            .reduce((minimum, obj) => obj.highestBid! < minimum ? obj.highestBid! : minimum, Infinity);

        return statistics.filter(statistic => statistic.highestBid === minValue || statistic.highestBid === null);
    }, [statistics]);
    const highestBid = useMemo(() => {

        return statistics
            .filter((s) => !!s.highestBid)
            .reduce((maxStat, obj) => obj.highestBid! > maxStat.highestBid! ? obj : maxStat, statistics[0]);
    }, [statistics]);
    const lowestChangeStart = useMemo(() => {

        return statistics
            .filter((s) => !!s.bidTurnover)
            .reduce((maxStat, obj) => obj.bidTurnover! < maxStat.bidTurnover! ? obj : maxStat, statistics[0]);
    }, [statistics]);
    const highestChangeStart = useMemo(() => {

        return statistics
            .filter((s) => !!s.bidTurnover)
            .reduce((maxStat, obj) => obj.bidTurnover! > maxStat.bidTurnover! ? obj : maxStat, statistics[0]);
    }, [statistics]);

    return <div className={css(styles.pageContainer)}>
        <div className={css(styles.mainContainer, styles.imagePagePart)}>
            <h2>Kastenveiling - beheer</h2>

            <h4>Statistieken per flipperkast</h4>
            <table className={'table'}>
                <thead>
                    <tr>
                        <th>Flipperkast</th>
                        <th>Start bedrag</th>
                        <th>Huidig bod</th>
                        <th>Percentage t.o.v. start bedrag</th>
                    </tr>
                </thead>
                <tbody>
                    {statistics.map((statistic) => <tr key={statistic.name} className={!statistic.bidTurnover ? 'bg-warning' : ''}>
                        <th>{statistic.name}</th>
                        <td><CurrencyEuro amount={statistic.startingPrice}/></td>
                        <td>{statistic.highestBid && <CurrencyEuro amount={statistic.highestBid}/>}</td>
                        <td>{statistic.bidTurnover && statistic.bidTurnover + '%'}</td>
                    </tr>)}
                </tbody>
            </table>

            <h4>Algemene statistieken</h4>
            <table className={'table'}>
                <thead>
                    <tr>
                        <th>Statistiek</th>
                        <th>Waarde(s)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>Hoogste bod</th>
                        <td>{highestBid && <>{highestBid.name} - <CurrencyEuro amount={highestBid.highestBid!}/></>}</td>
                    </tr>
                    <tr>
                        <th>Laagste bod</th>
                        <td>{lowestBids.map((stats) => <>{stats.name} {!stats.highestBid && '(geen bod)'}, </>)}</td>
                    </tr>
                    <tr>
                        <th>Hoogste verschil start - hoogste bod</th>
                        <td>{highestChangeStart && <>{highestChangeStart.name} - {highestChangeStart.bidTurnover}%</>}</td>
                    </tr>
                    <tr>
                        <th>Laagste verschil start - hoogste bod</th>
                        <td>{lowestChangeStart && <>{lowestChangeStart.name} - {lowestChangeStart.bidTurnover}%</>}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>;
};
