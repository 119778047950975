import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Dispatch} from 'redux';
import {
    TourSceneActionTypes,
    tourSceneDidActivateScene,
    tourSceneDidDeactivateScene,
    tourSceneFinishTour,
    tourSceneGoToNextStep
} from './actions';
import {css} from 'aphrodite/no-important';
import pinballFlipper from '../../assets/images/pinball-flipper.png';
import {Button} from 'react-bootstrap';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {RootState} from '../../reducer';
import {TourSceneReducerState, TourSceneStep} from './reducer';
import styles from './styles';
import {SaleExplained} from '../../components/SaleExplained';
import {HowSaleWorks} from '../../components/HowToSale';

export const TourScene: React.FunctionComponent = () => {
    const {currentStep: tourStep} = useSelector<RootState, TourSceneReducerState>((state) => state.tourScene);
    const dispatch = useDispatch<Dispatch<TourSceneActionTypes>>();
    const onGoToNextStep = () => dispatch(tourSceneGoToNextStep());

    useEffect(() => {
        dispatch(tourSceneDidActivateScene());

        return function cleanup() {
            dispatch(tourSceneDidDeactivateScene());
        };
    }, []);

    return <>
        <div
            className={css(styles.pageHeader)}
            style={{backgroundImage: `url('${pinballFlipper}')`}}
        >
            {tourStep === TourSceneStep.WELCOME && <><p>Welkom :)</p>
                <h2>Welkom bij de 2025 NFV Kasten Veiling</h2></>}
            {tourStep === TourSceneStep.BIDDING && <><p>Algemene "spelregels" voor de veiling</p>
                <h2>Belangrijke informatie over de veiling</h2></>}
            {tourStep === TourSceneStep.HOW_TO && <><p>Maar hoe dan? :)</p>
                <h2>Uitleg van de veiling website</h2></>}
        </div>

        <div className={css(styles.pageContent)}>
            {tourStep === TourSceneStep.WELCOME && <><p>Welkom op de website van de NFV Kasten Veiling, via deze korte
                rondleiding willen je graag kort uitleggen
                hoe de veiling in zijn werking zal gaan. Deze informatie kan je uiteraard ook later terug lezen nadat
                deze rondleiding is afgelopen.</p>

                <p>Graag verzoeken we je deze informatie goed door te lezen voordat je een bod plaatst, of je t.z.t. je
                    kast komt ophalen.</p>

                <div className={'text-end'}>
                    <Button onClick={() => onGoToNextStep()} variant={'outline-primary'}>
                        <FontAwesomeIcon icon={faChevronRight}/> Naar stap twee
                    </Button>
                </div>
            </>}

            {tourStep === TourSceneStep.BIDDING && <>
                <SaleExplained/>

                <div className={'text-end'}>
                    <Button onClick={() => onGoToNextStep()} variant={'outline-primary'}>
                        <FontAwesomeIcon icon={faChevronRight}/> Naar de volgende stap
                    </Button>
                </div>
            </>}

            {tourStep === TourSceneStep.HOW_TO && <>
                <HowSaleWorks/>

                <div className={'text-end'}>
                    <Button onClick={() => dispatch(tourSceneFinishTour())} variant={'outline-primary'}>
                        <FontAwesomeIcon icon={faChevronRight}/> Afronden en naar de veiling
                    </Button>
                </div>
            </>}
        </div>
    </>;
};
