import {combineEpics, Epic} from 'redux-observable';
import {filter, map, mergeMap} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {applicationModuleUpdateCurrentScene} from '../../modules/application/actions';
import {Scenes} from '../../scenes';
import {adminSceneDidActivateScene, adminSceneUpdateStatistics} from '../../scenes/Admin/actions';
import {swaggerApiService} from '../../services';
import {DependencyEpic} from '../../store';
import {swaggerApiServicePinballBidControllerReceivedStatsData} from '../../services/SwaggerApiService/actions/pinballStatistics';

const changeSceneApplicationModuleOnAdminSceneDidActivate: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(adminSceneDidActivateScene)),
        map(() => applicationModuleUpdateCurrentScene(Scenes.ADMIN))
    );

const loadStatisticsOnSceneDidActive: DependencyEpic = (action$, state$) => action$
    .pipe(
        filter(isActionOf(adminSceneDidActivateScene)),
        mergeMap(() => swaggerApiService.loadStatistics(state$.value.authenticationModule.sessionId!))
    );

const updateStatisticsOnReceivedStatisticsData: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(swaggerApiServicePinballBidControllerReceivedStatsData)),
        map(({payload}) => adminSceneUpdateStatistics(payload))
    );

export const adminSceneEpics = combineEpics(
    changeSceneApplicationModuleOnAdminSceneDidActivate,
    loadStatisticsOnSceneDidActive,
    updateStatisticsOnReceivedStatisticsData
);
