import {ActionType, createAction} from 'typesafe-actions';
import {BiddingStatsDTO} from '../../generated/swagger-api';

export const adminSceneDidActivateScene = createAction(
    'ADMIN_SCENE_DID_ACTIVATE'
)();

export const adminSceneDidDeactivateScene = createAction(
    'ADMIN_SCENE_DID_DEACTIVATE'
)();

export const adminSceneUpdateStatistics = createAction(
    'ADMIN_SCENE_UPDATE_STATISTICS'
)<BiddingStatsDTO[]>();

export type AdminSceneActionTypes = ActionType<typeof adminSceneDidActivateScene> | ActionType<typeof adminSceneDidDeactivateScene> |
    ActionType<typeof adminSceneUpdateStatistics>;
