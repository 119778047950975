import {createBrowserRouter, Outlet} from 'react-router-dom';
import {UnauthenticatedLayout} from './components/Layout/UnauthenticatedLayout';
import React from 'react';
import {ABOUT_THE_SALE, ADMIN, ERROR_SCENE, HOW_TO_SALE, MY_BIDS, NEW_SESSION, PINBALL_SINGLE, TOUR, WELCOME} from './routes';
import {PrivateRoute} from './components/PrivateRoute';
import {TourScene} from './scenes/Tour';
import {NewSessionScene} from './scenes/NewSession';
import {ErrorScene} from './scenes/Error';
import {MainLayout} from './components/Layout/MainLayout';
import {MyBidsScene} from './scenes/MyBids';
import {PinballScene} from './scenes/Pinball';
import {AboutTheSale} from './scenes/AboutTheSale';
import {HowToSale} from './scenes/HowToSale';
import {WelcomeScene} from './scenes/Welcome';
import {Admin} from './scenes/Admin';

export const router = createBrowserRouter([
    {
        element: <MainLayout><Outlet/></MainLayout>,
        children: [
            {
                path: MY_BIDS,
                element: <PrivateRoute component={MyBidsScene}/>
            },
            {
                path: WELCOME,
                element: <PrivateRoute component={WelcomeScene}/>
            },
            {
                path: PINBALL_SINGLE,
                element: <PrivateRoute component={PinballScene}/>
            },
            {
                path: ABOUT_THE_SALE,
                element: <PrivateRoute component={AboutTheSale}/>
            },
            {
                path: HOW_TO_SALE,
                element: <PrivateRoute component={HowToSale}/>
            },
            {
                path: ADMIN,
                element: <PrivateRoute component={Admin}/>
            }
        ]
    },
    {
        element: <UnauthenticatedLayout><Outlet/></UnauthenticatedLayout>,
        children: [
            {
                path: TOUR,
                element: <PrivateRoute component={TourScene}/>
            },
            {
                path: NEW_SESSION,
                element: <NewSessionScene/>
            },
            {
                path: ERROR_SCENE,
                element: <ErrorScene/>
            }
        ]
    },
    {
        path: '/loading',
        element: <></>
    }
]);

router.subscribe((state) => {
    if (state.location.pathname.indexOf('newsession') === -1 &&
        state.location.pathname.indexOf('loading') === -1) {
        localStorage.setItem('last-route', state.location.pathname);
    }
});
