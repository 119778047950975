import React, {useMemo} from 'react';
import {Props} from './Props';

const euroFormatted = new Intl.NumberFormat('nl-nL', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0
});

export const CurrencyEuro: React.FunctionComponent<Props> = ({amount}) => {
    const amountFormatted = useMemo(() => euroFormatted.format(amount), [amount]);

    return <>{amountFormatted}</>;
};
