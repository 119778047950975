import {combineEpics, Epic} from 'redux-observable';
import {filter, ignoreElements, map, tap} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {DependencyEpic} from '../../store';
import {tourSceneFinishTour, tourSceneGoToNextStep, tourSceneSetStep} from '../../scenes/Tour/actions';
import {TourSceneStep} from '../../scenes/Tour/reducer';
import {WELCOME} from '../../routes';
import {router} from '../../router';

const setNextStepOnGoToNextStep: DependencyEpic = (action$, state$) => action$
    .pipe(
        filter(isActionOf(tourSceneGoToNextStep)),
        map(() => {
            let nextStep: TourSceneStep = TourSceneStep.WELCOME;

            if (state$.value.tourScene.currentStep === TourSceneStep.WELCOME) {
                nextStep = TourSceneStep.BIDDING;
            } else if (state$.value.tourScene.currentStep === TourSceneStep.BIDDING) {
                nextStep = TourSceneStep.HOW_TO;
            }

            return tourSceneSetStep(nextStep);
        })
    );

const setLocalStorageAndRedirectOnFinishTour: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(tourSceneFinishTour)),
        tap(() => localStorage.setItem('tour-completed-2025', '1')),
        tap(() => router.navigate(WELCOME)),
        ignoreElements()
    );

export const tourSceneEpics: Epic = combineEpics(
    setNextStepOnGoToNextStep,
    setLocalStorageAndRedirectOnFinishTour
);
