import {createAction} from 'typesafe-actions';

export const swaggerApiServicePinballControllerSavedPinballBid = createAction(
    'SWAGGER_API_SERVICE_PINBALL_CONTROLLER_SAVED_PINBALL_BID'
)();

export const swaggerApiServicePinballControllerPlacingBidFailedReceivingDataWithUnauthorizedError = createAction(
    'SWAGGER_API_SERVICE_PINBALL_CONTROLLER_PLACING_BID_FAILED_RECEIVING_DATA_WITH_UNAUTHORIZED_ERROR'
)();

export const swaggerApiServicePinballControllerPlacingBidFailedReceivingDataWithUnexpectedError = createAction(
    'SWAGGER_API_SERVICE_PINBALL_CONTROLLER_PLACING_BID_FAILED_RECEIVING_DATA_WITH_UNEXPECTED_ERROR'
)();
