import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import {App} from './components/App';
import 'bootstrap/dist/css/bootstrap.min.css';
import {store} from './store';
import {ToastContainer} from 'react-toastify';
import './assets/css/main.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App/>
            <ToastContainer/>
        </Provider>
    </React.StrictMode>
);
