import React, {useMemo} from 'react';
import {Props} from './Props';
import {Badge} from 'react-bootstrap';
import pinball from '../../models/Pinball';
import {css} from 'aphrodite';
import styles from './styles';

export const PinballStatus: React.FunctionComponent<Props> = ({pinballGame, smallFontSize}) => {
    const {badgeType, text} = useMemo(() => pinball.statusBadge(pinballGame), [pinballGame]);
    const className = useMemo(() => smallFontSize ? css(styles.text) : undefined, [smallFontSize]);

    return <Badge bg={badgeType} className={className}>{text}</Badge>;
};

