import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {css} from 'aphrodite/no-important';
import styles from './styles';
import {RootState} from '../../reducer';
import {Col, Row} from 'react-bootstrap';
import pinballGif from '../../assets/images/pinball.gif';
import {CurrencyEuro} from '../../components/CurrencyEuro';
import {MyBidsSceneReducerState} from './reducer';
import {Dispatch} from 'redux';
import {MyBidsSceneActionTypes, myBidsSceneDidActivateScene, myBidsSceneDidDeactivateScene} from './actions';
import {NavLink} from 'react-router-dom';

export const MyBidsScene: React.FunctionComponent = () => {
    const {bids} = useSelector<RootState, MyBidsSceneReducerState>((state) => state.myBids);
    const dispatch = useDispatch<Dispatch<MyBidsSceneActionTypes>>();

    useEffect(
        () => {
            dispatch(myBidsSceneDidActivateScene());

            return function cleanup() {
                dispatch(myBidsSceneDidDeactivateScene());
            };
        },
        []
    );

    return <>
        <div className={css(styles.pageContainer)}>
            {!bids && <>
                <Row>
                    <Col xs={12} md={8}>
                        <div className={css(styles.mainContainer, styles.imagePagePart)}>
                            <h2>Bezig met ophalen van je biedingen...</h2>

                            <p>
                                <span className={css(styles.loadingSubTitle)}>
                                    Misschien spelen we ondertussen stiekem wel een snel potje&nbsp;
                                </span>
                                😁
                            </p>
                        </div>
                    </Col>
                    <Col xs={12} md={4}>
                        <img src={pinballGif} className={'img-fluid text-end'} alt={'Helaas niet gevonden...'}/>
                    </Col>
                </Row>
            </>}
            {bids && <>
                <div className={css(styles.mainContainer, styles.imagePagePart)}>
                    <h2>Mijn biedingen</h2>

                    <p>Op deze pagina zie je een overzicht met al je biedingen, de biedingen in rood zijn flipperkasten
                        waarop je bent overboden door iemand anders.</p>

                    <table className={'table bg-transparent'}>
                        <thead>
                            <tr>
                                <th>Flipperkast</th>
                                <th>Bedrag</th>
                                <th/>
                            </tr>
                        </thead>
                        <tbody>
                            {bids.map((bid) => <React.Fragment key={bid.id}>
                                <tr>
                                    <td>{bid.pinballName}</td>
                                    <td className={bid.highestBid ? 'text-success' : 'text-danger'}><CurrencyEuro
                                        amount={bid.amount}/></td>
                                    <td className={'text-end'}>
                                        <NavLink className={'btn btn-outline-primary'} to={`/flipperkast/${bid.pinballSlug}`}>Ga naar
                                            flipperkast</NavLink>
                                    </td>
                                </tr>
                            </React.Fragment>)}
                        </tbody>
                    </table>
                </div>
            </>}
        </div>
    </>;
};
