import {createEpicMiddleware, Epic} from 'redux-observable';
import {rootReducer, RootState} from './reducer';
import {rootEpic} from './epic';
import {Action} from 'typesafe-actions';
import {applicationModuleApplicationColdBoot} from './modules/application/actions';
import {configureStore} from '@reduxjs/toolkit';

const epicMiddleware = createEpicMiddleware<Action, Action, void, void>();
export type DependencyEpic = Epic<any, any, RootState, void>;
export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
        epicMiddleware
    ),
    devTools: {
        name: 'NFV Veiling site'
    }
});

epicMiddleware.run(rootEpic);
store.dispatch(applicationModuleApplicationColdBoot());
