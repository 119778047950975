import {Reducer} from 'redux';
import {
    AuthenticationModuleActionsType,
    authenticationModuleFinishedUserLogout,
    authenticationModuleLoadedUserProfileData,
    authenticationModuleUserAuthenticated
} from './actions';
import {getType} from 'typesafe-actions';
import {UserDTO} from '../../generated/swagger-api';

export interface AuthenticationModuleReducerState {
    authenticationAvailable: boolean;
    authenticated: boolean;
    sessionId: string | null;
    userProfile: UserDTO | null;
}

const initialState: AuthenticationModuleReducerState = {
    authenticationAvailable: false,
    authenticated: false,
    sessionId: null,
    userProfile: null
};

export const authenticationModuleReducer: Reducer<AuthenticationModuleReducerState, AuthenticationModuleActionsType> =
    (state: AuthenticationModuleReducerState = initialState, action: AuthenticationModuleActionsType): AuthenticationModuleReducerState => {
        switch (action.type) {
            case getType(authenticationModuleUserAuthenticated):
                return {
                    ...state,
                    authenticated: true,
                    sessionId: action.payload
                };
            case getType(authenticationModuleFinishedUserLogout):
                return {
                    ...state,
                    authenticated: false
                };
            case getType(authenticationModuleLoadedUserProfileData):
                return {
                    ...state,
                    userProfile: action.payload
                };
            default:
                return {
                    ...state
                };
        }
    };
