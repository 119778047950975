import {ActionType, createAction} from 'typesafe-actions';
import {Scenes} from '../../scenes';

export const applicationModuleApplicationColdBoot = createAction(
    'APPLICATION_MODULE_APPLICATION_COLD_BOOT'
)();

export const applicationModuleBootWithSessionUrl = createAction(
    'APPLICATION_MODULE_BOOT_WITH_SESSION_URL'
)<string>();

export const applicationModuleApplicationBootCompleted = createAction(
    'APPLICATION_MODULE_APPLICATION_BOOT_COMPLETED'
)();

export const applicationModuleUpdateCurrentScene = createAction(
    'APPLICATION_MODULE_UPDATE_CURRENT_SCENE'
)<Scenes>();

export type ApplicationModuleActionTypes = ActionType<typeof applicationModuleApplicationColdBoot> |
    ActionType<typeof applicationModuleBootWithSessionUrl> |
    ActionType<typeof applicationModuleApplicationBootCompleted> |
    ActionType<typeof applicationModuleUpdateCurrentScene>;
