import {Reducer} from 'react';
import {ApplicationModuleActionTypes, applicationModuleApplicationBootCompleted, applicationModuleUpdateCurrentScene} from './actions';
import {getType} from 'typesafe-actions';
import {Scenes} from '../../scenes';

export interface ApplicationModuleReducerState {
    applicationBooted: boolean;
    currentScene: Scenes;
}

const initialState: ApplicationModuleReducerState = {
    applicationBooted: false,
    currentScene: Scenes.LOADING
};

export const applicationModuleReducer: Reducer<ApplicationModuleReducerState, ApplicationModuleActionTypes> =
    (state: ApplicationModuleReducerState = initialState, action: ApplicationModuleActionTypes): ApplicationModuleReducerState => {
        switch (action.type) {
            case getType(applicationModuleApplicationBootCompleted):
                return {
                    ...state,
                    applicationBooted: true
                };
            case getType(applicationModuleUpdateCurrentScene):
                return {
                    ...state,
                    currentScene: action.payload
                };
            default:
                return {
                    ...state
                };
        }
    };
